// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const ROOT_URL = 'https://api-uat.gasmonkeybd.com';
// export const ROOT_URL = 'http://192.168.30.125:8801'; //Zamir bhai pc
// export const ROOT_URL = 'http://192.168.30.123:8801'; //Rabbi bhai pc
// export const ROOT_URL = 'http://192.168.30.167:8801'; //Mahmud pc
// export const ROOT_URL = 'http://localhost:8801'; //locally
export const environment = {
  production: false,
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYnJhc2thbSIsImEiOiJja3NqcXBzbWoyZ3ZvMm5ybzA4N2dzaDR6In0.RUAYJFnNgOnn80wXkrV9ZA'
  }
}
