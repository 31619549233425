import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TextData } from '../../../../pages/shared/model/common/text-data';
import { ServiceListControlWrapper } from '../master-model/service-list-control-wrapper';
import { MasterButtonService } from '../master-service/master-button-service';
import { MasterListService } from '../master-service/master-list-service';
import { TableModel } from '../../../../pages/shared/model/common/table.model';
import { DatePipe } from '@angular/common';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MasterButtonField } from '../master-model/master-button-field';
import { MatDialog } from '@angular/material/dialog';
import { DialogImageViewerComponent } from '../../../../pages/shared/components/dialog-image-viewer/dialog-image-viewer.component';
import { LocalStorageService } from '../../../../pages/shared/service/local-storage-service';

@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html',
  styleUrls: ['./master-list.component.scss']
})

export class MasterListComponent implements OnInit {
  orderList=[
    {id:'pending', value:'Pending'},
    {id: 'canceled', value:'Canceled'},
    {id: 'completed', value:'Completed'}
  ];

  isShowAdvanceSearchIcon:boolean
  @Input() showAdvanceSearchIcon
  @Output() isShowAdvanceSearch = new EventEmitter<string>();
  @Output() searchText = new EventEmitter<string>();


  defaultImage = '../../../../../assets/img/gas-monkey-logo.png';
  data: any;
  public pageSizeOptions = [10, 20, 100, 500];
  displayedColumns: string[];
  isShowLoading = false;
  dataSource = new MatTableDataSource();

  masterColumns: TextData[];
  masterListService: MasterListService;
  masterButtonService: MasterButtonService;
  tableRawData: any;
  tableModel: TableModel;
  originalDataSource: any[] = [];
  sort: MatSort;
  paginatorLength: number;
  pageSize:any;
  pageNumber:any;
  outerButtonFields: MasterButtonField[];
  innerButtonFields: MasterButtonField[];
  innerButtonFieldsForView: MasterButtonField[];
  authorityName: any;
  accessMenu = [];
  serviceListControlWrapper: ServiceListControlWrapper<MasterListService, MasterButtonService>;
  @Output() buttonClickedMethod: EventEmitter<{ element: any, method: string ,checked?:boolean}> = new EventEmitter<{ element: any, method: string,checked?:boolean }>();
  @Output() pageAndSort: EventEmitter<{
    pageInfo?: {
      limit: number,
      offset: number
    },
    sortInfo?: {
      sortField: string,
      sortDirection: string
    }
  }> = new EventEmitter<{ pageInfo: { limit: number, offset: number }, sortInfo: { sortField: string, sortDirection: string } }>();
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) set matSort(matSort: MatSort) {
    this.sort = matSort;
  }

  @Input()
  set setServiceListControlWrapper(serviceListControlWrapper: ServiceListControlWrapper<MasterListService, MasterButtonService>) {
    if (serviceListControlWrapper) {
      this.serviceListControlWrapper = serviceListControlWrapper;

      this.tableRawData = serviceListControlWrapper.data
      this.masterListService = serviceListControlWrapper.listService;
      this.masterButtonService = serviceListControlWrapper.buttonService;

      this.createTableData()
    }
  }

  constructor(public dialog: MatDialog,
    private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {

       this.authorityName = this.localStorageService.getAuthorityName();
       console.log("authority name---",this.authorityName);
       this.accessMenu.push(this.localStorageService.getRoleName());
       console.log(" showAdvanceSearchIcon---", this.showAdvanceSearchIcon);
       if(this.showAdvanceSearchIcon){
        this.isShowAdvanceSearchIcon=true;
       }


  }

  createTableData() {
    this.dataSource = new MatTableDataSource(this.tableRawData);
    // this.dataSource.paginator = this.paginator;
    const buttonFields = this.masterButtonService.buttonFields;
    this.outerButtonFields = buttonFields.filter(field => ['add','tracker-map'].includes(field.controlType));
    this.innerButtonFields = buttonFields.filter(field => field.controlType !== 'add');
    const infoDefinition = _.cloneDeep(this.serviceListControlWrapper.listService.fieldInfo);
    const infoFields = _.cloneDeep(this.masterListService.getFields(infoDefinition));
    this.tableModel = new TableModel()
    infoFields
      .forEach(field => {
          const columns = new TextData(field);
          this.tableModel.addColumn(columns);
      });

    this.buildColumns();
    this.setShowedValue();

    // this.initSort();
    if (this.serviceListControlWrapper.isPaginated) {
      this.initPaginator();
    }

    if (!this.originalDataSource || !this.originalDataSource.length) {
      this.originalDataSource = _.cloneDeep(this.dataSource.data);
    }
  }


  buildColumns() {
    this.masterColumns = this.tableModel.columns;
    this.sortColumns();
    this.displayedColumns = new Array<string>();
    const buttonFields = this.masterButtonService.buttonFields;
    this.innerButtonFieldsForView = buttonFields.filter(field => field.controlType === 'view');
    console.log('>>>>>>',this.innerButtonFieldsForView);

    const displayColumns = this.masterColumns.filter(col => col.canShow).map(col => col.key);
    const displayListName = this.masterColumns.filter(col => col.listName);
    displayColumns.forEach(col =>{
      console.log('*****build..',col);
      if((col == 'status' &&  displayListName[0]['listName'] == 'product' && this.localStorageService.getAuthorityName() == 'VIEWER') || col == 'action' && this.localStorageService.getAuthorityName() == 'VIEWER' && !this.innerButtonFieldsForView[0] ){

      }
      else{
        this.displayedColumns.push(col);
      }

    });
  }

  sortColumns() {
    this.masterColumns = _.sortBy(this.masterColumns, ['order']);
  }

  setShowedValue() {
    this.dataSource.data.forEach(element => {
      element['showedValue'] = {};
      element['defaultColor'] = {};
      this.masterColumns.forEach(column => {
        let requiredObject = element;
        if (element['isEmptyList'] === 'true' && element['isDraftItem'] === 'true') {
          requiredObject = element['temp'];
        }

        switch (column.controlType) {
          case 'datepicker':
            const datePipe = new DatePipe('bn-BD');
            element['showedValue'][column.key] = requiredObject[column.key] ?
              datePipe.transform(new Date(requiredObject[column.key]), 'dd/MM/yyyy') : '';
            break;
          case 'textarea':
            element['showedValue'][column.key] = requiredObject[column.key] && requiredObject[column.key].length > 20 ?
              requiredObject[column.key].substring(0, 20) + '...' : requiredObject[column.key];
            break;
          default:
            element['showedValue'][column.key] = requiredObject[column.key];
        }
        this.setColor(element, column.key);
      });
    });
  }

  setColor(element: string | any, key: string) {
    if (!element['showedValue'][key] || typeof element['showedValue'][key] !== 'string') {
      element['defaultColor'][key] = 'black';
    } else if (element['showedValue'][key].toLowerCase() === 'pending' || element['showedValue'][key].toLowerCase() === 'skip') {
      element['defaultColor'][key] = 'orange';
    } else if (element['showedValue'][key].toLowerCase() === 'rejected' || element['showedValue'][key].toLowerCase() === 'returned') {
      element['defaultColor'][key] = 'red';
    } else if (element['showedValue'][key].toLowerCase() === 'approved' || element['showedValue'][key].toLowerCase() === 'paid' || element['showedValue'][key].toLowerCase() === 'settlement') {
      element['defaultColor'][key] = 'green';
    } else if (element['showedValue'][key].toLowerCase() === 'sent_for_correction' || element['showedValue'][key].toLowerCase() === 'sent_to_admin') {
      element['defaultColor'][key] = 'Chocolate';
    } else if (element['showedValue'][key].toLowerCase() === 'corrected') {
      element['defaultColor'][key] = 'darkcyan';
    } else {
      element['defaultColor'][key] = 'black';
    }
  }

  initSort() {
    this.dataSource.sort = this.sort;
    if (this.serviceListControlWrapper.sortInfo) {
      this.sort.active = this.serviceListControlWrapper.sortInfo.active;
      this.sort.direction = this.serviceListControlWrapper.sortInfo.sortDirection as SortDirection;
      this.sort.sortChange.emit();
    }
  }

  initPaginator() {
    if (this.serviceListControlWrapper.totalDataSize !== null && this.serviceListControlWrapper.totalDataSize !== undefined) {
      this.paginatorLength = this.serviceListControlWrapper.totalDataSize;
    }
    if (this.serviceListControlWrapper.pageInfo) {
      this.pageNumber = this.serviceListControlWrapper.pageInfo.pageIndex;
      this.pageSize= this.serviceListControlWrapper.pageInfo.pageSize;
    }
    // this.dataSource.paginator = this.paginator;
    // console.log('datasource-paginator===>>>>',this.dataSource.paginator);
  }

  sortData(params: Sort) {
    if (!params) {
      return;
    }
    const direction: SortDirection = params.direction;
    this.dataSource.data = direction
      ? _.orderBy(this.dataSource.data, [params.active], [direction])
      : this.originalDataSource;

    console.log(this.dataSource.data)

    if (this.serviceListControlWrapper.isPaginated) {
      this.initPaginator();
    }
  }

  showTogglechnage(element: any, event:any){
    this.buttonClickedMethod
      .emit({ element: element,method: 'showToogle', checked : event.checked });
  }

  onRatingClick(element:any){
      // console.log('rating****',element);
      this.buttonClickedMethod.emit({element: element, method: 'rating'});
  }

  toggle(element: any, buttonField: MasterButtonField,event:any) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType ,checked : event.checked});
  }

  edit(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  add(buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: null, method: buttonField.controlType });
    return;
  }

  trackerMap(buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: null, method: buttonField.controlType });
    return;
  }

  view(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  map(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  forward(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  mapForward(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  received(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  canceled(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  resetPassword(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  reply(element: any, buttonField: MasterButtonField){
    this.buttonClickedMethod
    .emit({ element: element, method: buttonField.controlType });
  }

  payment(element: any, buttonField: MasterButtonField){
    this.buttonClickedMethod
    .emit({ element: element, method: buttonField.controlType });
  }

  order(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  delete(element: any, buttonField: MasterButtonField) {
    this.buttonClickedMethod
      .emit({ element: element, method: buttonField.controlType });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.dataSource.paginator.firstPage();

  }
  pageChange(event: PageEvent) {
    this.pageAndSort.emit({
      pageInfo: {
        offset: event.pageIndex,
        limit: event.pageSize
      }
    });
  }

  openImageDialog(event){
    console.log(event.srcElement.src);
    const dialogRef = this.dialog.open(DialogImageViewerComponent, {

      // disableClose: true,
      width: '550px',
      height: '550px',
      data: {
        title: 'Image',
        obj: event.srcElement.src
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('image closed');
    });

  }

  // shouldShowButton(element) {
  //   return (!element.orderStatus) || (element.orderStatus != 'nouserfound' && element.orderStatus != 'noaction');
  // }

  shouldShowMapButton(element) {
    return (!element.orderStatus) || (element.orderStatus =='picked');
  }

  shouldShowActionButton(element){
    return  !(element.type =='SYSTEM');

  }

  shouldShowReplyButton(element){

    return (!element.issueStatus) || (element.issueStatus =='pending');
  }

  shouldShowForwardButton(element){
    return (!element.orderStatus) || (element.orderStatus =='noaction') || (element.orderStatus =='nouserfound')  || (element.orderStatus =='picked') || (element.orderStatus =='accepted') || (element.orderStatus =='pending');
    // return (!element.orderStatus) || (element.orderStatus =='noaction') || (element.orderStatus =='nouserfound')  || (element.orderStatus =='picked') || (element.orderStatus =='accepted') || (element.orderStatus =='received') || (element.orderStatus =='forward') || (element.orderStatus =='pending');
  }

  shouldShowOrderModifyButton(element){
    return (!element.orderStatus) || (element.orderStatus =='nouserfound')  || (element.orderStatus =='picked') || (element.orderStatus =='accepted') || (element.orderStatus =='received') || (element.orderStatus =='forward') || (element.orderStatus =='pending')  || (element.orderStatus =='noaction');
  }

  shouldShowReceivedButton(element){
    return (!element.orderStatus) || (element.orderStatus =='picked') || (element.orderStatus =='pending');
  }

  shouldShowCanceledButton(element){
    return (!element.orderStatus) || (element.orderStatus =='nouserfound')  || (element.orderStatus =='picked') || (element.orderStatus =='accepted') || (element.orderStatus =='received') || (element.orderStatus =='forward') || (element.orderStatus =='pending');
    // return (!element.orderStatus) || (element.orderStatus !='noaction') || (element.orderStatus =='nouserfound') || (element.orderStatus !='forward') || (element.orderStatus !='reject') || (element.orderStatus !='pending') || (element.orderStatus =='accept') || (element.orderStatus =='picked') || (element.orderStatus =='received');
  }

  convertToDateWithYear(data: any) {
    // console.log('****>>>',data);
    if (data) {
      let dateStr = Date.parse(data);
      // console.log(data,"=======>>>>>>>",dateStr)
      let date = new Date(dateStr);
      let str =date.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', year: 'numeric', hour12: true, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
      // console.log("=======>>>>>>>",str)
      return str;
    } else {
      return data;
    }
  }

  timeCounterConvert(data:any){
      let demo = '';
      let str =  this.convertToDateWithYear(data);
      let pickDate=new Date(str).getTime();
      let now = new Date().getTime();
      let distance = now - pickDate;
      let hours = new Date(distance).getHours();
      let minites = new Date(distance).getMinutes();
      let seconds = new Date(distance).getSeconds();
      hours = isNaN(hours) ? 0 : hours;
      minites = isNaN(minites) ? 0 : minites;
      seconds =  isNaN(seconds) ? 0 : seconds;
      demo =  hours + 'h ' + minites + 'm ' + seconds + 's ';
      demo = demo.replace('0h', ' ');
      demo = demo.replace('0m', ' ');
      demo =demo.replace('0s', ' ');
      console.log('***',demo);
      return demo;
  }

  onDiscountTypeChange(event , buttonField: MasterButtonField){
    //console.log(event);
    //console.log(buttonField);
    this.buttonClickedMethod
    .emit({ element: event, method: buttonField.controlType });
      return;

  }


  // search

  showAdvanceSearchDialog(event:any){
    this.isShowAdvanceSearch.emit('openBranchDialog');
    console.log('open advance dialog');

  }

  search(text: string) {
    console.log(text);
    // this.searchText.emit(text);
    // const filterValue = (event.target as HTMLInputElement).value;
    const filterValue = text;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  shouldShowButtonByExistRole(button:any){
    let access = true;
    if(this.accessMenu[0]){
      this.accessMenu[0].forEach(el =>{
        // console.log(el.menu, el.accessList);
        if(el.menu === button.label){
           if(el.accessList && !el.accessList.includes("MODIFY")){
             access = false;
           }
           else{
            access = true;
           }
        }
      });
    }
    return access;
  }
}
